// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.ReactModalPortal {
    z-index: 5;
    position: relative;
}

body {
    background-color: #b4c67c;
    background-image: url("../images/app_background.png");
    background-attachment: fixed;
}

a {
    color: #000;
}

.pt-10p {
    padding-top: 10%;
}

.container {
    min-height: 100vh;
}

#app .container {
    background-color: rgba(255, 255, 255, 0.2);
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-link {
    color: #000;
}

.card-control {
    flex: 1 0 auto;
}

.control-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;

    button,
    .nav-link {
        margin: 0 0 15px 15px;
    }
}

.nav.nav-detailed {
    padding-bottom: 15px;

    .nav-link {
        border: 1px solid rgba(142, 142, 142, 0.3);
        margin-bottom: 5px;
    }

    .nav-link:not(.active) {
        background-color: rgba(142, 142, 142, 0.2);
    }
}

.icon-eng::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background: url("../icons/eng.svg") no-repeat;
}

.icon-ru::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background: url("../icons/ru.svg") no-repeat;
}

.icon-ua::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background: url("../icons/ua.svg") no-repeat;
}

.icon-next:before {
    content: '\e800';
}

.icon-prev:before {
    content: '\e800';
    transform: rotateY(180deg);
}

.header {
    position: sticky;
    top: 0;
    z-index: 5;

    &__toolbar {
        background-color: #466039;
        border-radius: 4px;
        margin-bottom: 5px;
    }
}

.nav__main-menu {
    position: sticky;
    top: 57px;
    z-index: 5;
}

.nav-btn-exit .icon-logout,
.nav-btn-menu .icon-menu {
    color: #000000;
    font-size: 24px;
}

.nav-btn-exit .icon-logout:hover,
.nav-btn-menu .icon-menu:hover {
    color: #3490dc;
}

.nav-btn-menu .icon-menu {
    font-size: 24px;
    display: none;
}

.btn-back-mob {
    display: none;
    margin: 10px 0;
}

.btn-back-web {
    margin: 10px 0;
}

.list-group-no-data {
    font-size: 14px;
    font-weight: 700;
    padding: 25px;
    text-align: center;
    background-color: rgba(172, 172, 172, 0.33);
    color: #6c6c6c;
}

/* Auth pages */
.grecaptcha-badge {
    visibility: hidden;
}

.form__field {
    margin: 0 3%;
    font-size: 0.6rem;
    text-align: justify;
    color: #777;
}

.form__field .form__comment-link,
.form__field .form__comment-link:visited,
.form__field .form__comment-link:hover {
    color: blue;
}

/* Check Box */

.check-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    input[type="checkbox"] {
        display: none;
    }

    i {
        font-size: 24px;
    }
}

/* modal */

.s-modal {
    position: absolute;
    border: 1px solid #cccccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;

    &__header {
        border-bottom: 1px solid #ccc;
        padding: 20px 20px 0;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-icon {
            font-size: 24px;
        }
    }

    &__body {
        padding: 20px;
    }

    &__footer {
        padding: 20px;
        border-top: 1px solid #ccc;
    }

    .button.close {
        align-self: flex-start;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            margin-left: 15px;
        }
    }
}

/*game menu*/

.game-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &__item {
        width: 250px;
        color: #fff;
        background-color: #3490dc;
        text-align: center;
    }

    .nav-link {
        margin-top: 10px;
    }
}

.abacus-game__result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*abacus game*/

.abacus-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;

    &__result {
        input {
            font-size: 32px;
            width: 200px;
            display: inline-block;
            color: #3490dc;
            margin-top: 20px;
            text-align: center;
        }
    }
}

/*Abacus*/

.abacus {
    border: 0.5vw solid #2471A3;
    width: 95%;
    height: 30vw;
    border-radius: 0.5vw;
}

.abacus__inner {
    border: 0.5vw solid #000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-top {
        width: 100%;
        height: 27%;
        border-bottom: 0.25vw solid #95A5A6;
        display: flex;
        justify-content: space-around;
    }

    &-separator {
        width: 100%;
        height: 0.5vw;
        background-color: #2471A3;
    }

    &-bottom {
        width: 100%;
        height: calc(73% - 0.5vw);
        border-top: 0.25vw solid #95A5A6;
        display: flex;
        justify-content: space-around;
    }

    &-block {
        width: 0.5vw;
        height: 100%;
        background-color: #95A5A6;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-chip {
        width: 1000%;
        height: 500%;
        transform: translateX(-50%) translateX(0.125vw);
        margin: 2px;
        cursor: pointer;
    }

    &-chip:before {
        content: "";
        width: 100%;
        height: 0;
        display: block;
        border-left: 0.68vw solid transparent;
        border-right: 0.68vw solid transparent;
    }

    &-chip.row-1:before {
        border-bottom: 1.36vw solid $row_1;
    }

    &-chip.row-2:before {
        border-bottom: 1.36vw solid $row_2;
    }

    &-chip.row-3:before {
        border-bottom: 1.36vw solid $row_3;
    }

    &-chip.row-4:before {
        border-bottom: 1.36vw solid $row_4;
    }

    &-chip.row-5:before {
        border-bottom: 1.36vw solid $row_5;
    }

    &-chip.row-6:before {
        border-bottom: 1.36vw solid $row_6;
    }

    &-chip.row-7:before {
        border-bottom: 1.36vw solid $row_7;
    }

    &-chip:after {
        content: "";
        width: 100%;
        height: 0;
        display: block;
        border-left: 0.68vw solid transparent;
        border-right: 0.68vw solid transparent;
    }

    &-chip.row-1:after {
        border-top: 1.36vw solid $row_1;
    }

    &-chip.row-2:after {
        border-top: 1.36vw solid $row_2;
    }

    &-chip.row-3:after {
        border-top: 1.36vw solid $row_3;
    }

    &-chip.row-4:after {
        border-top: 1.36vw solid $row_4;
    }

    &-chip.row-5:after {
        border-top: 1.36vw solid $row_5;
    }

    &-chip.row-6:after {
        border-top: 1.36vw solid $row_6;
    }

    &-chip.row-7:after {
        border-top: 1.36vw solid $row_7;
    }

    &-chip.bubble {
        visibility: hidden;
    }
}

/*Range*/

.range {
    list-style: none;
    display: flex;
    height: 50px;

    ul {
        padding: 0;
    }

    li {
        width: 60px;
        height: 8px;
        background-color: #9c9c9c;
        position: relative;
    }

    li.active, {
        background-color: #007bff;

        .range-point {
            background-color: #007bff;
        }
    }

    li.active.row-1, {
        background-color: $row_1;

        .range-point {
            background-color: $row_1;
        }
    }

    li.active.row-2, {
        background-color: $row_2;

        .range-point {
            background-color: $row_2;
        }
    }

    li.active.row-3, {
        background-color: $row_3;

        .range-point {
            background-color: $row_3;
        }
    }

    li.active.row-4, {
        background-color: $row_4;

        .range-point {
            background-color: $row_4;
        }
    }

    li.active.row-5, {
        background-color: $row_5;

        .range-point {
            background-color: $row_5;
        }
    }

    li.active.row-6, {
        background-color: $row_6;

        .range-point {
            background-color: $row_6;
        }
    }

    li.active.row-7, {
        background-color: $row_7;

        .range-point {
            background-color: $row_7;
        }
    }

    li:first-child {
        width: 1px;
    }

    &-point {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: #9c9c9c;
        position: absolute;
        top: -50%;
        right: -8px;
        z-index: 3;
        cursor: pointer;
    }

    &-point::after {
        content: attr(data-value);
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translate(-50%);
    }
}

/*settings game*/

.settings-game {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    &__ranges {
        display: flex;
        flex-direction: column;
    }

    .range,
    .settings-game__field,
    .settings-game__buttons {
        padding: 0 10px;
    }

    .number-field {
        width: 250px;
    }

    .settings-game__buttons {
        margin: 25px 0;
    }

    &__field {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
            margin-right: 15px;
        }
    }

    &__radio-group {
        .custom-radio {
            margin-bottom: 15px;
        }

        .custom-control-label span {
            display: block;
            font-size: 0.8rem;
        }
    }

    &__title {
        padding: 10px 0 5px;
    }

    .custom-radio {
        padding-left: 25px;
    }
}

/*number field*/

/* Chrome, Safari, Edge, Opera */
.number-field input[type=number]::-webkit-outer-spin-button,
.number-field input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-field input[type=number] {
    -moz-appearance: textfield;
}

.number-field__button {
    cursor: pointer;
}

/*list card*/

.list-card {
    cursor: pointer;
    margin-bottom: 0.75rem;
}

.list-card .list-card__title,
.list-card .list-card__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.list-card .list-card__title {
    border-bottom: 1px solid #8e8e8e;
    font-weight: 700;
    padding-bottom: 5px;
}

.list-card__title .list-card__title-name {
    font-size: 16px;
}

.list-card__body .list-card__body-name {
    font-weight: 700;
    padding-right: 5px;
}

/*game*/

.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;

    &__number {
        color: #dc7633;
        font-weight: 700;
        font-size: 15rem;
        line-height: 15rem;

        &.animation {
            animation: showup 0.5s;
        }
    }

    &__result {
        text-align: center;

        label {
            font-size: 2rem;
        }

        input {
            font-size: 5rem;
            text-align: center;
        }
    }

    &__answer {
        font-size: 1.2rem;
    }
}

.mt0 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

@keyframes showup {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.game .control-buttons {
    justify-content: space-around;
}

/* media selectors */

@media (max-width: 990px) {
    .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-6 + .col-6 {
        padding-top: 15px;
    }

    .nav-btn-menu .icon-menu {
        display: block;
    }

    .nav__main-menu {
        display: none;
        background-color: #466039;
        border-radius: 4px;
        margin-bottom: 5px;

        &_open {
            display: block;
        }

        .nav-link {
            font-weight: 700;
        }
    }

    .btn-back-web {
        display: none;
    }

    .btn-back-mob {
        display: block;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .control-buttons button {
        flex: 0 0 100%;
    }
}

@media (max-width: 420px) {
    .s-modal {
        width: auto;
        max-width: 400px;
    }
}

@media (max-width: 355px) {
    .btn {
        padding-left: 0.50rem;
        padding-right: 0.50rem;
    }
}

@media(orientation: portrait) {
    .abacus {
        border: 0.5vw solid #2471A3;
        width: 60vw;
        height: 70vh;
        border-radius: 0.5vw;
    }

    .abacus__inner {
        border: 0.5vw solid #000;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        &-top {
            width: 27%;
            height: 100%;
            border-right: 0.25vw solid #95A5A6;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }

        &-separator {
            width: 0.5vw;
            height: 100%;
            background-color: #2471A3;
        }

        &-bottom {
            width: calc(73% - 0.5vw);
            height: 100%;
            border-left: 0.25vw solid #95A5A6;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }

        &-block {
            width: 100%;
            height: 0.5vw;
            background-color: #95A5A6;
            display: flex;
            flex-direction: row;
        }

        .block-top {
            justify-content: flex-start;
        }

        .block-bottom {
            justify-content: flex-end;
        }

        &-chip {
            width: 8vw;
            height: 1000%;
            transform: translate(0.125vw, -75%);
            margin: 2px;
            cursor: pointer;
        }

        &-chip:before {
            content: "";
            width: 0;
            height: 150%;
            display: block;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-1:before {
            border-right: 3vw solid $row_1;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-2:before {
            border-right: 3vw solid $row_2;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-3:before {
            border-right: 3vw solid $row_3;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-4:before {
            border-right: 3vw solid $row_4;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-5:before {
            border-right: 3vw solid $row_5;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-6:before {
            border-right: 3vw solid $row_6;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-7:before {
            border-right: 3vw solid $row_7;
            border-left: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip:after {
            content: "";
            width: 0;
            height: 150%;
            display: block;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
            transform: translate(100%, -100%);
        }

        &-chip.row-1:after {
            border-left: 3vw solid $row_1;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-2:after {
            border-left: 3vw solid $row_2;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-3:after {
            border-left: 3vw solid $row_3;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-4:after {
            border-left: 3vw solid $row_4;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-5:after {
            border-left: 3vw solid $row_5;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-6:after {
            border-left: 3vw solid $row_6;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.row-7:after {
            border-left: 3vw solid $row_7;
            border-right: none;
            border-top: 1.5vw solid transparent;
            border-bottom: 1.5vw solid transparent;
        }

        &-chip.bubble {
            visibility: hidden;
        }
    }
}
